import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// v-bg
import bg from './plugins/bg'
// 埋点插件
import tracking from './plugins/tracking'
import 'vant/es/toast/style'

import dayjs from 'dayjs'

const app = createApp(App)

app.config.globalProperties.$dayjs = dayjs //全局使用dayjs

app.use(bg)
app.use(tracking, {
  router
})
app.use(store)
app.use(router)
app.mount('#app')
